import store from "@/store";
import {
  ASSESSMENT_QUESTIONS,
  CHECK_TENANT_EXISTENCE
} from "@/store/modules/candidates/constants";
import { clean_string } from "@/utils/global";
interface InterviewReportIcons {
  icon: string;
  key: string;
  bg_color: string;
  color: string;
}
const INTERVIEW_REPORT_ICONS: InterviewReportIcons[] = [
  {
    icon: require("@/assets/icons/assessments/briefcase.svg"),
    key: "Relevant Educational Background",
    bg_color: "#E9FFF2",
    color: "#27D86D"
  },
  {
    icon: require("@/assets/icons/assessments/teacher.svg"),
    key: "Ability To Empathize And Shift Perspectives",
    bg_color: "#FCEFFD",
    color: "#EE49FD"
  },
  {
    icon: require("@/assets/icons/assessments/smileys.svg"),
    key: "Communication Skills",
    bg_color: "#FFF1EF",
    color: "#FF8473"
  },
  {
    icon: require("@/assets/icons/assessments/profile-2user.svg"),
    key: "Resilience And Perseverance",
    bg_color: "#FFF7E5",
    color: "#FFB815"
  },
  {
    icon: require("@/assets/icons/assessments/24-support.svg"),
    key: "Proactivity",
    bg_color: "#EEFAFF",
    color: "#33BFFF"
  },
  {
    icon: require("@/assets/icons/assessments/frame.svg"),
    key: "Career Goals",
    bg_color: "#FCEFFD",
    color: "#EE49FD"
  },
  {
    icon: require("@/assets/icons/assessments/message-question.svg"),
    key: "Adaptability",
    bg_color: "#FFF1EF",
    color: "#FF8473"
  },
  {
    icon: require("@/assets/icons/assessments/ghost.svg"),
    key: "Strategic Thinking",
    bg_color: "#FFF7E5",
    color: "#FFB815"
  },
  {
    icon: require("@/assets/icons/assessments/story.svg"),
    key: "Reflective",
    bg_color: "#EEFAFF",
    color: "#33BFFF"
  },
  {
    icon: require("@/assets/icons/assessments/ghost.svg"),
    key: "Analytical",
    bg_color: "#FFF7E5",
    color: "#FFB815"
  },
  {
    icon: require("@/assets/icons/assessments/frame.svg"),
    key: "Collaborative",
    bg_color: "#FCEFFD",
    color: "#EE49FD"
  }
];
export async function process_tenant_registration(
  user_id: number
): Promise<void> {
  // Check tenant existence
  await store.dispatch(`candidate/${CHECK_TENANT_EXISTENCE}`, user_id);
  // If tenant not exist => register new tenant
  // if (!tenant_exist)
  //   await store.dispatch(`candidate/${REGISTER_TENANT}`, {
  //     tp_name: "principles"
  //   });

  // Get questions
  await store.dispatch(`candidate/${ASSESSMENT_QUESTIONS}`, {});
}

export function get_report_icon(key: string): InterviewReportIcons {
  const icon = INTERVIEW_REPORT_ICONS.find(
    (val: InterviewReportIcons) => clean_string(val.key) === clean_string(key)
  );
  if (icon) return icon;
  else return INTERVIEW_REPORT_ICONS[0];
}

export function capitalize_words(string: string): string {
  return string
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
