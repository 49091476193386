





























































import Vue from "vue";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  GET_NEP_REPORT,
  NEP_INTERVIEW_ID
} from "@/store/modules/candidates/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import {
  capitalize_words,
  get_report_icon
} from "@/utils/principle_assessments";
import { mapActions, mapGetters, mapMutations } from "vuex";
import UserRating from "@/components/shared/view_profile/UserRating.vue";
import { ReportDetail } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "NepReport",
  components: {
    UserRating
  },
  data() {
    return {
      nep_interview_id: 0,
      is_report: false,
      report_data: [] as ReportDetail[]
    };
  },
  async mounted() {
    const details = await this.get_nep_interview_id();
    if (details.interview_status !== Interview.Status.Finished) {
      this.set_root_error("No Report Available");
      await this.$router.push("nep-test");
    }
    if (details) {
      const res = await this.get_nep_report(details.id);
      if (res) {
        for (const [key, val] of Object.entries(res)) {
          const icon_details = get_report_icon(key);
          const obj = {
            bg_color: icon_details.bg_color,
            color: icon_details.color,
            icon: icon_details.icon,
            key: capitalize_words(key),
            rating: (val as any)?.rating,
            reason: (val as any)?.reason
          };
          this.report_data.push(obj);
        }
        this.is_report = true;
      }
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapActions("candidate", {
      get_nep_interview_id: NEP_INTERVIEW_ID,
      get_nep_report: GET_NEP_REPORT
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    })
  }
});
